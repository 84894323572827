// extracted by mini-css-extract-plugin
export var office__content = "style-module--office__content--9a68d";
export var office__content__address = "style-module--office__content__address--50a9e";
export var office__content__address_jp = "style-module--office__content__address_jp--d84a6";
export var office__content__address_jp_link = "style-module--office__content__address_jp_link--c31be";
export var office__content__address_jp_link_hovered = "style-module--office__content__address_jp_link_hovered--2cd93";
export var office__content__address_link = "style-module--office__content__address_link--394b5";
export var office__content__address_link_hovered = "style-module--office__content__address_link_hovered--fefbc";
export var office__content__body = "style-module--office__content__body--c14fe";
export var office__content__item = "style-module--office__content__item--5b555";
export var office__content__item_with_jp = "style-module--office__content__item_with_jp--dedb9";
export var office__content__label = "style-module--office__content__label--57ed7";
export var office__title = "style-module--office__title--4d171";