// extracted by mini-css-extract-plugin
export var mail__content = "style-module--mail__content--2784d";
export var mail__content__address_link = "style-module--mail__content__address_link--9f41b";
export var mail__content__address_link_hovered = "style-module--mail__content__address_link_hovered--14e70";
export var mail__content__management = "style-module--mail__content__management--d5ad6";
export var mail__content__manager = "style-module--mail__content__manager--b6535";
export var mail__content__manager_en = "style-module--mail__content__manager_en--a4e89";
export var mail__content__manager_jp = "style-module--mail__content__manager_jp--1b4ad";
export var mail__content__phone_num = "style-module--mail__content__phone_num--3a934";
export var mail__title = "style-module--mail__title--8193b";
export var mail__title_wrap = "style-module--mail__title_wrap--0ed12";