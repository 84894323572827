import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './style.module.sass'

import MailSection from '../../model/mailSection'
import OfficeSection from '../../model/officeSection'

const ContactPageComponent = ({ _dataContact }) => {
    return (
        <div className={styles.contact}>
            {(_dataContact.mail.toEn !== '' ||
                _dataContact.mail.toJp !== '' ||
                _dataContact.mail.address ||
                _dataContact.mail.phoneNum) && (
                <section className={styles.contact__mail}>
                    <MailSection
                        _isDisplayManagement={_dataContact.mail.addManagementText}
                        _managerEn={_dataContact.mail.toEn}
                        _managerJp={_dataContact.mail.toJp}
                        _address={_dataContact.mail.address}
                        _phoneNum={_dataContact.mail.phoneNum}
                    />
                </section>
            )}
            {_dataContact.officeList.length !== 0 && (
                <section className={styles.contact__office}>
                    <OfficeSection _officeList={_dataContact.officeList} />
                </section>
            )}
        </div>
    )
}

export default ContactPageComponent

ContactPageComponent.propTypes = {
    _dataContact: PropTypes.shape({
        mail: PropTypes.shape({
            addManagementText: PropTypes.bool.isRequired,
            toEn: PropTypes.string,
            toJp: PropTypes.string,
            address: PropTypes.string,
            phoneNum: PropTypes.string,
        }),
        officeList: PropTypes.arrayOf(
            PropTypes.shape({
                office: PropTypes.shape({
                    label: PropTypes.string,
                    addressEn: PropTypes.string,
                    addressJp: PropTypes.string,
                    googleMapLink: PropTypes.string,
                }),
            })
        ),
    }).isRequired,
}
