import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

const MailSection = ({ _isDisplayManagement, _managerEn, _managerJp, _address, _phoneNum }) => {
    const mailToLink = `mailto:${_address}`

    return (
        <>
            <div className={styles.mail__title_wrap}>
                <h2 className={styles.mail__title}>Mail</h2>
            </div>
            <div className={styles.mail__content}>
                {_isDisplayManagement && (
                    <p className={styles.mail__content__management}>(management)</p>
                )}
                {(_managerEn !== '' || _managerJp !== '') && (
                    <p className={styles.mail__content__manager}>
                        {_managerEn !== '' && (
                            <span className={styles.mail__content__manager_en}>{_managerEn}</span>
                        )}
                        {_managerJp !== '' && (
                            <span className={styles.mail__content__manager_jp}>
                                （担当:{_managerJp}）
                            </span>
                        )}
                    </p>
                )}
                {_address !== '' && (
                    <p>
                        <Hover hoverClass={styles.mail__content__address_link_hovered}>
                            <a
                                href={mailToLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.mail__content__address_link}
                            >
                                {_address}
                            </a>
                        </Hover>
                    </p>
                )}
                {_phoneNum !== '' && <p className={styles.mail__content__phone_num}>{_phoneNum}</p>}
            </div>
        </>
    )
}

export default MailSection

MailSection.defaultProps = {
    _managerEn: '',
    _managerJp: '',
    _address: '',
    _phoneNum: '',
}

MailSection.propTypes = {
    _isDisplayManagement: PropTypes.bool.isRequired,
    _managerEn: PropTypes.string,
    _managerJp: PropTypes.string,
    _address: PropTypes.string,
    _phoneNum: PropTypes.string,
}
