import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './style.module.sass'

import Hover from '../../utils/hover'

const OfficeSection = ({ _officeList }) => {
    const getItemClass = (_jpAddress) => {
        let baseClass = `${styles.office__content__item} `
        if (_jpAddress !== '') baseClass += `${styles.office__content__item_with_jp}`
        return baseClass
    }

    return (
        <>
            <h2 className={styles.office__title}>Office</h2>
            <ul className={styles.office__content}>
                {_officeList.map((office, index) => (
                    <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${office.office.label}${index}`}
                        className={getItemClass(office.office.addressJp)}
                    >
                        {office.office.label !== '' && (
                            <h3 className={styles.office__content__label}>{office.office.label}</h3>
                        )}
                        <div className={styles.office__content__body}>
                            {office.office.addressEn !== '' &&
                                office.office.googleMapLink !== '' && (
                                    <p className={styles.office__content__address}>
                                        <Hover
                                            hoverClass={
                                                styles.office__content__address_link_hovered
                                            }
                                        >
                                            <a
                                                className={styles.office__content__address_link}
                                                href={office.office.googleMapLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                dangerouslySetInnerHTML={{
                                                    __html: office.office.addressEn,
                                                }}
                                            >
                                                {/* {_address} */}
                                            </a>
                                        </Hover>
                                    </p>
                                )}
                            {office.office.addressEn !== '' &&
                                office.office.googleMapLink === '' && (
                                    <p
                                        className={styles.office__content__address}
                                        dangerouslySetInnerHTML={{
                                            __html: office.office.addressEn,
                                        }}
                                    />
                                )}
                            {office.office.addressJp !== '' &&
                                office.office.googleMapLink !== '' && (
                                    <p className={styles.office__content__address_jp}>
                                        <Hover
                                            hoverClass={
                                                styles.office__content__address_jp_link_hovered
                                            }
                                        >
                                            <a
                                                className={styles.office__content__address_jp_link}
                                                href={office.office.googleMapLink}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                dangerouslySetInnerHTML={{
                                                    __html: office.office.addressJp,
                                                }}
                                            >
                                                {/* {_addressJp} */}
                                            </a>
                                        </Hover>
                                    </p>
                                )}
                            {office.office.addressJp !== '' &&
                                office.office.googleMapLink === '' && (
                                    <p
                                        className={styles.office__content__address_jp}
                                        dangerouslySetInnerHTML={{
                                            __html: office.office.addressJp,
                                        }}
                                    />
                                )}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default OfficeSection

OfficeSection.propTypes = {
    _officeList: PropTypes.arrayOf(
        PropTypes.shape({
            office: PropTypes.shape({
                label: PropTypes.string,
                addressEn: PropTypes.string,
                addressJp: PropTypes.string,
                googleMapLink: PropTypes.string,
            }),
        })
    ).isRequired,
}
