import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Seo from '../components/utils/seo'

import ContactPageComponent from '../components/page/contactPageComponent'

const ContactPage: React.FC<PageProps<GatsbyTypes.ContactPageQuery>> = ({ data }) => {
    return <ContactPageComponent _dataContact={data.allWpPage.edges[0].node.contact} />
}

export default ContactPage

export const Head = () => <Seo _title="Contact" />

export const pageQuery = graphql`
    query ContactPage {
        allWpPage(filter: { slug: { eq: "contact" } }) {
            edges {
                node {
                    contact {
                        mail {
                            addManagementText
                            toEn
                            toJp
                            address
                            phoneNum
                        }
                        officeList {
                            office {
                                label
                                addressEn
                                addressJp
                                googleMapLink
                            }
                        }
                    }
                }
            }
        }
    }
`
